import React, { createContext, useReducer } from 'react'

const LOCALSTORAGE_TIMEOUT = 1000 * 60 * 60 * 24 //24 hours

const reducer = (state, action): any => {
  switch (action.type) {
    case 'updateLocation':
      return { ...state, center: action.center, zoom: action.zoom }
    case 'updateHitsUrl':
      return { ...state, urls: { ...state.urls, [action.id]: action.url } }
    default:
      return state
  }
}

const defaultState = {}

const FinderContext = createContext(defaultState)

export const FinderContextProvider = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(
    reducer,
    (() => {
      try {
        const finderState = JSON.parse(localStorage.getItem('finderState'))
        if (Date.now() - finderState.date > LOCALSTORAGE_TIMEOUT) {
          localStorage.removeItem('finderState')
          return defaultState
        }
        return finderState
      } catch {
        return defaultState
      }
    })()
  )
  return (
    <FinderContext.Provider value={{ state, dispatch }}>
      {children}
    </FinderContext.Provider>
  )
}

export default FinderContext
